import React from "react";

import { graphql } from "gatsby";
import { FormattedMessage, injectIntl } from "react-intl";

import withLayout from "../layout";
import SEO from "../components/seo";

import "./services.scss";

const ServicesList = () => {
    return (
        <div className="services-list">
            <div className="services-list__item">
                <h3 className="services-list__text">
                    <FormattedMessage id="services.services-individuals" />
                </h3>
                <ul className="services-list__list">
                    <li>
                        <FormattedMessage id="services.services-individuals-one" />
                    </li>
                    <li>
                        <FormattedMessage id="services.services-individuals-two" />
                    </li>
                    <li>
                        <FormattedMessage id="services.services-individuals-three" />
                    </li>
                    <li>
                        <FormattedMessage id="services.services-individuals-four" />
                    </li>
                    <li>
                        <FormattedMessage id="services.services-individuals-five" />
                    </li>
                    <li>
                        <FormattedMessage id="services.services-individuals-six" />
                    </li>
                    <li>
                        <FormattedMessage id="services.services-individuals-seven" />
                    </li>
                    <li>
                        <FormattedMessage id="services.services-individuals-eight" />
                    </li>
                </ul>
            </div>
            <div className="services-list__item">
                <h3 className="services-list__text">
                    <FormattedMessage id="services.services-kids" />
                </h3>
                <ul className="services-list__list">
                    <li>
                        <FormattedMessage id="services.services-kids-one" />
                    </li>
                    <li>
                        <FormattedMessage id="services.services-kids-two" />
                    </li>
                    <li>
                        <FormattedMessage id="services.services-kids-three" />
                    </li>
                    <li>
                        <FormattedMessage id="services.services-kids-four" />
                    </li>
                    <li>
                        <FormattedMessage id="services.services-kids-five" />
                    </li>
                    <li>
                        <FormattedMessage id="services.services-kids-six" />
                    </li>
                    <li>
                        <FormattedMessage id="services.services-kids-seven" />
                    </li>
                    <li>
                        <FormattedMessage id="services.services-kids-eight" />
                    </li>
                </ul>
            </div>
            <div className="services-list__item">
                <h3 className="services-list__text">
                    <FormattedMessage id="services.services-couples" />
                </h3>
                <ul className="services-list__list">
                    <li>
                        <FormattedMessage id="services.services-couples-one" />
                    </li>
                    <li>
                        <FormattedMessage id="services.services-couples-two" />
                    </li>
                    <li>
                        <FormattedMessage id="services.services-couples-three" />
                    </li>
                    <li>
                        <FormattedMessage id="services.services-couples-four" />
                    </li>
                    <li>
                        <FormattedMessage id="services.services-couples-five" />
                    </li>
                </ul>
            </div>
        </div>
    );
};

const Services = (props) => {
    const { intl } = props;
    const titleText = intl.formatMessage({ id: "services.services-title" });

    return (
        <div>
            <SEO
                title={titleText}
                keywords={[
                    `brief therapy`,
                    `San Francisco`,
                    `Psychotherapy`,
                    `MFT`,
                ]}
                lang={props.pageContext.locale}
            />
            <article className="services">
                <header className="services__header">
                    <h1>
                        <FormattedMessage id="services.services-title" />
                    </h1>
                    <h2>
                        <FormattedMessage id="services.services-therapy" />
                    </h2>
                    <p>
                        <FormattedMessage
                            id="services.services-problems"
                            values={{
                                strongIn: <strong>, strongOut: </strong>,
                            }}
                        />
                    </p>
                </header>
                <main className="services__main">
                    <section className="services__block">
                        <ServicesList />
                    </section>
                    <section className="services__fees">
                        <h2>
                            <FormattedMessage id="services.services-fees" />
                        </h2>
                        <p>
                            <FormattedMessage
                                id="services.services-fees-text"
                                values={{
                                    strongIn: <strong>, strongOut: </strong>,
                                }}
                            />
                        </p>
                        <h3>
                            <FormattedMessage id="services.services-policy" />
                        </h3>
                        <p>
                            <FormattedMessage
                                id="services.services-policy-text"
                                values={{
                                    strongIn: <strong>, strongOut: </strong>,
                                }}
                            />
                        </p>
                        <h3>
                            <FormattedMessage id="services.services-scale" />
                        </h3>
                        <p>
                            <FormattedMessage id="services.services-scale-text" />
                        </p>
                        <h3>
                            <FormattedMessage id="services.services-insurance" />
                        </h3>
                        <p>
                            <FormattedMessage
                                id="services.services-insurance-text"
                                values={{
                                    strongIn: <strong>, strongOut: </strong>,
                                }}
                            />
                        </p>
                    </section>
                </main>
            </article>
        </div>
    );
};

const customProps = {
    localeKey: "services",
};

export default withLayout(customProps)(injectIntl(Services));

export const pageQuery = graphql`
    {
        fileName: file(relativePath: { eq: "gloria-lake-chicago-crop.jpg" }) {
            childImageSharp {
                gatsbyImageData(width: 800, height: 450, layout: CONSTRAINED)
            }
        }
    }
`;
